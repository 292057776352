import React, {Children, Component, forwardRef} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import languages from "../../i18n";
import {IState} from "../../types/state";
import Slick from "react-slick"
import cls from "./shared.module.scss"

class StroykaSlickBase extends Component {
    slickRef: { slickGoTo: (arg0: number, arg1: boolean) => void; };
    private element: any;
    public state: any;
    public children?: any;

    constructor(props: any) {
        super(props);

        this.state = {
            preventClick: false,
            activeSlides: this.getActiveSlides(this.getStartPosition()),
            slidesToShow: null,
        };
    }

    componentDidMount() {
        if (!this.element) {
            return;
        }

        this.element.addEventListener("mousedown", this.onMousedown);

        if (this.slickRef) {
            this.slickRef.slickGoTo(this.getStartPosition(), true);
        }
    }

    componentDidUpdate(prevProps: { locale?: any; children?: any; responsive?: any; }) {
        // const {locale: prevLocale, children: prevChildren} = prevProps;
        const {children: prevChildren} = prevProps;
        const prevLocale = "en";
        // @ts-ignore
        const language = languages[prevLocale];
        const prevDirection = language ? language.direction : 'ltr';
        // @ts-ignore
        // const {locale: currLocale, children: currChildren} = this.props;
        const {children: currChildren} = this.props;
        const currLocale = "en";
        // @ts-ignore
        const {direction: currDirection} = languages[currLocale];

        if (currDirection !== prevDirection && this.slickRef) {
            this.slickRef.slickGoTo(this.getStartPosition(), true);
        }

        if (currChildren !== prevChildren) {
            setTimeout(() => {
                this.setState({
                    activeSlides: this.getActiveSlides(this.getStartPosition())
                });
            }, 0);
        }

        const {responsive: prevResponsive} = prevProps;
        // @ts-ignore
        const {responsive: currResponsive} = this.props;

        if (currResponsive !== prevResponsive) {
            this.unsubscribeMedias();
        }
    }

    componentWillUnmount() {
        this.unsubscribeMedias();

        if (!this.element) {
            return;
        }

        this.element.removeEventListener("mousedown", this.onMousedown);
    }

    getSlidesCount() {
        //@ts-ignore
        return Children.toArray(this.props.children).length;
    }

    getStartPosition() {
        // @ts-ignore
        let {infinite} = this.props;
        // @ts-ignore
        const {locale = "en"} = this.props;
        // @ts-ignore
        const language = languages[locale];
        const direction = language ? language.direction : 'ltr';

        infinite = infinite === true || infinite === undefined;

        if (direction === "ltr") {
            return 0;
        }

        const slidesToShow = null;
        // const slidesCount = 3
        const slidesCount = this.getSlidesCount();

        if (!infinite) {
            // @ts-ignore
            return Math.max(0, slidesCount - slidesToShow);
        }
        // @ts-ignore
        return (Math.ceil(slidesCount / slidesToShow) - 1) * slidesToShow;
    }

    // @ts-ignore
    getActiveSlides(currentIndex) {
        const slidesToShow = null;
        const activeSlides = [];
        const slidesCount = this.getSlidesCount();

        const firstSlide = Math.max(
            0,
            // @ts-ignore
            Math.min(slidesCount - slidesToShow, currentIndex)
        );
        // @ts-ignore
        const lastSlide = Math.min(slidesCount, firstSlide + slidesToShow);

        for (let i = firstSlide; i < lastSlide; i += 1) {
            activeSlides.push(i);
        }

        return activeSlides;
    }

    getSlidesToShow() {
        // @ts-ignore
        const {responsive, slidesToShow} = this.props;

        let result = slidesToShow || 1;

        if (responsive) {
            // @ts-ignore
            responsive.forEach((options) => {
                const {matches} = matchMedia(`(max-width: ${options.breakpoint}px)`);

                if (matches && options.settings.slidesToShow) {
                    result = options.settings.slidesToShow;
                }
            });
        }

        return result;
    }

    unsubscribeMedias = () => {
    };

    originalSlickNext = () => {
    };

    originalSlickPrev = () => {
    };

    setRef = (ref: any) => {
        this.element = ref;
    };

    onMousedown = (event: { screenX: any; screenY: any; }) => {
        const downX = event.screenX;
        const downY = event.screenY;

        const onMousemove = (moveEvent: {
            screenX: number;
            screenY: number;
            cancelable: any;
            preventDefault: () => void;
        }) => {
            const {preventClick} = this.state;

            if (preventClick) {
                return;
            }

            const distance = Math.sqrt(
                Math.abs(downX - moveEvent.screenX) ** 2 +
                Math.abs(downY - moveEvent.screenY) ** 2
            );

            if (moveEvent.cancelable && distance > 3) {
                moveEvent.preventDefault();
            }

            if (distance > 15) {
                this.setState({preventClick: true});
            }
        };
        const onMouseup = () => {
            this.setState({preventClick: false});

            document.removeEventListener("mousemove", onMousemove);
            document.removeEventListener("mouseup", onMouseup);
        };

        document.addEventListener("mousemove", onMousemove);
        document.addEventListener("mouseup", onMouseup);
    };

    beforeChange = (oldIndex: any, newIndex: any) => {
        // @ts-ignore
        const {beforeChange} = this.props;

        if (beforeChange) {
            beforeChange(oldIndex, newIndex);
        }
        setTimeout(() => {
            this.setState({activeSlides: this.getActiveSlides(newIndex)});
        }, 0);
    };

    setSlickRef = (ref: { slickNext?: any; slickPrev?: any; slickGoTo?: (arg0: number, arg1: boolean) => void; }) => {
        // @ts-ignore
        const {forwardRef} = this.props;

        if (forwardRef) {
            forwardRef(ref);
        }

        if (ref && ref !== this.slickRef) {
            this.originalSlickNext = ref.slickNext;
            this.originalSlickPrev = ref.slickPrev;

            ref.slickNext = this.slickNext;
            ref.slickPrev = this.slickPrev;
        }
        // @ts-ignore
        this.slickRef = ref;
    };

    slickNext = () => {
        // @ts-ignore
        const {locale} = this.props;
        // @ts-ignore
        const {direction} = languages[locale];

        if (direction === "rtl") {
            this.originalSlickPrev();
        } else {
            this.originalSlickNext();
        }
    };

    slickPrev = () => {
        // @ts-ignore
        const {locale} = this.props;
        // @ts-ignore
        const {direction} = languages[locale];

        if (direction === "rtl") {
            this.originalSlickNext();
        } else {
            this.originalSlickPrev();
        }
    };

    createMedias() {
        // @ts-ignore
        const {responsive, slidesToShow} = this.props;
        if (responsive && responsive.length > 0) {
            // @ts-ignore
            const subscriptions: (() => void)[] = [];

            const createMedia = (query: string, slidesToShow: any) => {
                const media = matchMedia(query);

                const onChange = () => {
                    const {matches} = media;

                    if (matches && slidesToShow) {
                        this.setState(() => ({slidesToShow}));
                    }
                };

                if (media.addEventListener) {
                    media.addEventListener("change", onChange);
                } else {
                    media.addListener(onChange);
                }

                subscriptions.push(() => {
                    if (media.removeEventListener) {
                        media.removeEventListener("change", onChange);
                    } else {
                        media.removeListener(onChange);
                    }
                });
            };

            createMedia(
                `(min-width: ${responsive[0].breakpoint}.02px)`,
                slidesToShow || 1
            );

            responsive.forEach((options: { breakpoint: any; settings: { slidesToShow: any; }; }, index: number) => {
                const query = [`(max-width: ${options.breakpoint}px)`];

                if (responsive.length - 1 !== index) {
                    query.push(`(min-width: ${responsive[index + 1].breakpoint}.02px)`);
                }

                createMedia(query.join(" and "), options.settings.slidesToShow);
            });

            this.unsubscribeMedias = () => {
                subscriptions.forEach((x) => x());
            };
        }
    }

    render() {
        // @ts-ignore
        const {children, forwardRef, locale, beforeChange, autoplay, autoplaySpeed, ...otherProps} = this.props;

        const {preventClick, activeSlides, slidesToShow} = this.state;
        // @ts-ignore
        const language = languages[locale];
        const direction = language ? language.direction : 'ltr';

        const classes = classNames(cls["slick-prevent-click"],
            preventClick && cls["slick-prevent-click--active"]
        );

        let reversedChildren = Children.toArray(children);

        if (direction === "rtl") {
            reversedChildren = [...children];
            reversedChildren.reverse();
        }

        reversedChildren.map((slide: any, index: number) => {
            const slideClasses = classNames(
                activeSlides.includes(index) && cls["correct-slick-active"]
            );

            return (
                <div key={index} dir={direction} className={slideClasses}>
                    {slide}
                </div>
            );
        });

        return (
            <div className={classes} onMouseDown={this.onMousedown} ref={this.setRef} style={{height: "100%"}}>
                <Slick
                    {...otherProps}
                    autoplay={autoplay}
                    rtl={false}
                    beforeChange={this.beforeChange}
                    autoplaySpeed={autoplaySpeed}
                    infinite={
                        // @ts-ignore
                        otherProps.infinite && Children.count(children) > slidesToShow
                    }
                    // @ts-ignore
                    ref={this.setSlickRef}
                >
                    {reversedChildren}
                </Slick>
            </div>
        );
    }
}

const mapStateToProps = (state: IState) => ({
    locale: state.locale.code
});

const StroykaSlick = connect(mapStateToProps)(StroykaSlickBase);
// @ts-ignore
// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => <StroykaSlick forwardRef={ref} {...props} />);