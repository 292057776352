import {useEffect, useState} from "react";

export const useWindowWidth = () => {
    const [windowSize, setWindowSize] = useState();

    useEffect(() => {
        setWindowSize(window.innerWidth);
    }, []);

    return windowSize
}

export const withHooksHOC = (Component) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const screenWidth = useWindowWidth();

        return <Component width={screenWidth} {...props} />;
    };
};